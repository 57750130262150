import { getPagination6Pages } from './get-pagination-6-pages';
import { type PaginationItem, type PaginationProps } from './types';

/**
 * The maximum number of pages that can be displayed inline without needing to collapse into ellipsis or other forms of pagination.
 */
export const MAX_INLINE_PAGES = 5;
/**
 * The maximum number of total pages that can be displayed in the pagination based on the current design.
 */
export const MAX_TOTAL_PAGES = 9999;

export function getPaginationPages({
  totalPages = 1,
  page = 1,
  showLastPage = true,
}: PaginationProps): PaginationItem[] {
  if (!Number.isInteger(totalPages) || totalPages < 1) {
    throw new Error('Invalid totalPages value');
  }
  if (!Number.isInteger(page) || page < 1) {
    throw new Error('Invalid page value');
  }

  // For small number of pages, we can display all pages inline
  if (totalPages <= MAX_INLINE_PAGES) {
    return Array.from({ length: totalPages }, (_, index) => ({
      value: index + 1,
      type: 'PAGE',
      ...(index + 1 === page && { isActive: true }), // Set active page
    }));
  }

  const safeTotalPages = Math.min(totalPages, MAX_TOTAL_PAGES);

  /**
   * 6 elements pagination is a special case, because we need to squash the pagination elements logic
   */
  if (safeTotalPages === 6) {
    return getPagination6Pages({ page, showLastPage });
  }

  const pages: PaginationItem[] = [
    {
      value: 1,
      type: 'PAGE',
      ...(page === 1 && { isActive: true }),
    },
  ];

  /**
   * Biggest center element value, based on 7 elements structure
   * When the last page is not shown, we shift the center to the left
   */
  const maxCenterValue = showLastPage ? safeTotalPages - 3 : safeTotalPages - 2;
  /**
   * Calculate the normalized center value based on the current page to be able to render middle pages
   */
  const normalizedCenterValue = Math.min(Math.max(4, page), maxCenterValue);

  // Function to add an ellipsis to the pagination
  const addEllipsis = (small = false) =>
    pages.push({
      type: small ? 'ELLIPSIS_SMALL' : 'ELLIPSIS',
    });

  // Function to add a page to the pagination
  const addPage = (value: number, isAdditional = false) => {
    const item: PaginationItem = { value, type: 'PAGE' };
    if (isAdditional) item.isAdditional = true;
    if (value === page) item.isActive = true;
    pages.push(item);
  };

  if (page === 4) {
    /**
     * For the desktop view, the gap is not that big, so we render all pages.
     * 1,2,3,4,5 ... 10
     * But on mobile, user should see: 1 ... 4 ... 10
     * To do this, we need to have both ellipsis and additional page element.
     */
    addEllipsis(true);
    addPage(2, true);
  } else if (page > 4) {
    // If we are far enough from the first page, show ellipsis
    addEllipsis();
  } else {
    // If we in the first 3 pages, show all pages up to the center
    addPage(2);
  }

  /**
   * Middle section is always 3 pages, based on normalizedCenterValue
   * Surrounding pages can be hidden on mobile devices (isAdditional) if we are in the middle of the pagination
   */
  addPage(Math.max(3, normalizedCenterValue - 1), page > 3);
  addPage(normalizedCenterValue, page < 4 || page > maxCenterValue);
  addPage(
    Math.min(
      normalizedCenterValue + 1,
      showLastPage ? safeTotalPages - 2 : safeTotalPages - 1
    ),
    page <= maxCenterValue
  );

  /**
   * The second last element can have 2 different behaviors based on the showLastPage prop
   * 1. If showLastPage is true, second last element can be or ellipsis or a second last page (safeTotalPages - 1)
   * 2. If showLastPage is false, second last element can be or ellipsis or the last page (safeTotalPages)
   */
  if (showLastPage) {
    if (safeTotalPages - page > 3) {
      // Far from the last page
      addEllipsis();
    } else if (safeTotalPages - page === 3) {
      /**
       * For this corner case, we will render ellipsis on mobile and the second last page on desktop
       */
      addEllipsis(true);
      addPage(safeTotalPages - 1, true);
    } else {
      // Close to the last page
      addPage(safeTotalPages - 1);
    }
  } else {
    if (page < safeTotalPages - 2) {
      // Far from the last page
      addEllipsis();
    } else if (page === safeTotalPages - 2) {
      /**
       * For this corner case, we will render ellipsis on mobile and the second last page on desktop
       */
      addEllipsis(true);
      addPage(safeTotalPages, true);
    } else {
      // Close to the last page
      addPage(safeTotalPages);
    }
  }

  // Add the last page if showLastPage is true
  if (showLastPage) {
    addPage(safeTotalPages);
  }

  return pages;
}
