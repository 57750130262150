import { forwardRef, useState } from 'react';
import { IconButton } from '@volvo-cars/react-icons';
import { TextLikeInput } from './text-input';
import { type RangeLikeProps } from './types';

interface BaseDateInputProps extends RangeLikeProps {
  /**
   * The earliest date/time to accept, in the `yyyy-mm-dd` format.
   */
  min?: string;

  /**
   * The latest date/time to accept, in the `yyyy-mm-dd` format.
   */
  max?: string;

  /**
   * What type of information to autocomplete in the input.
   */
  autoComplete?: string;
}

type ControlledProps = {
  /**
   * Value of the input.
   *
   * Makes the input controlled.
   */
  value: string;

  /**
   * Fires immediately when the input’s value is changed by the user (for example, it fires on every keystroke).
   */
  onChange: React.ChangeEventHandler<HTMLInputElement>;

  defaultValue?: never;
};

type UncontrolledProps = {
  /**
   * Default value of an uncontrolled input.
   */
  defaultValue?: string;

  /**
   * Fires immediately when the input’s value is changed by the user (for example, it fires on every keystroke).
   */
  onChange?: React.ChangeEventHandler<HTMLInputElement>;

  value?: never;
};

export type DateInputUncontrolledProps = BaseDateInputProps & UncontrolledProps;
export type DateInputControlledProps = BaseDateInputProps & ControlledProps;
export type DateInputProps = BaseDateInputProps &
  (ControlledProps | UncontrolledProps);

/**
 * Lets the users select a date from the native calendar dropdown.
 * Should be used when it is helpful for the user to see what weekday a selected date is.
 *
 * When entering a well known date (such as a birth date), prefer a TextInput with a date pattern.
 */
export const DateInput = forwardRef<HTMLInputElement, DateInputProps>(
  function DateInput(
    { value, onChange, onBlur, defaultValue, ...props }: DateInputProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) {
    const [isUncontrolledBlank, setIsUncontrolledBlank] = useState(
      !value && !defaultValue
    );
    function updateIsBlank(event: React.FormEvent<HTMLInputElement>) {
      // Use constraints validation to determine if the input is completely blank.
      // `value` will be empty even if the input is partially filled, but `badInput` will be true.
      const { validity } = event.currentTarget;
      const initiallyRequired = event.currentTarget.required;
      if (!initiallyRequired) {
        event.currentTarget.required = true;
      }
      setIsUncontrolledBlank(validity.valueMissing && !validity.badInput);
      if (!initiallyRequired) {
        event.currentTarget.required = false;
      }
    }

    const isBlank = typeof value === 'string' ? !value : isUncontrolledBlank;
    return (
      <TextLikeInput
        {...props}
        value={value}
        defaultValue={defaultValue}
        type="date"
        ref={ref}
        onBlur={(event) => {
          onBlur?.(event);
          updateIsBlank(event);
        }}
        onChange={(event) => {
          onChange?.(event);
          updateIsBlank(event);
        }}
        data-blank={isBlank ? '' : undefined}
        contentAfter={
          <IconButton
            icon="calendar"
            className="ml-8 -mr-8"
            variant="clear"
            // No need for a separate button for screen reader users,
            // they will interact with the native form controls.
            aria-hidden
            tabIndex={-1}
            aria-label=""
            onClick={(event) => {
              const input = event.currentTarget.parentElement?.querySelector(
                'input[type=date]'
              ) as HTMLInputElement | null;
              if (input) {
                try {
                  input.showPicker();
                  // Safari has the `showPicker` method but it does nothing and does not throw
                  // so we can't use feature detection.
                  if (
                    /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
                  ) {
                    throw new Error();
                  }
                } catch (error) {
                  // Use brute force to trigger the date picker.
                  input.click();
                  input.focus();
                }
              }
            }}
          />
        }
      />
    );
  }
);
