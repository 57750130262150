import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export type PopoverBehaviour = 'auto' | 'manual';

/**
 * A popover attribute is required to turn an element into a popover.
 */
export type PopoverProps<T extends HTMLElement> = {
  popover: PopoverBehaviour;
  ref: React.RefObject<T>;
};

export type UsePopoverOptions<T extends HTMLElement> = {
  /**
   * Optional reference to the popover element. If not provided, one will be created.
   */
  ref?: React.RefObject<T>;
  /**
   * The behaviour of the popover.
   *
   * @default 'auto'
   */
  behaviour?: PopoverBehaviour;
  /**
   * Callback triggered on the popover ToggleEvent.
   */
  onToggle?: (event: ToggleEvent) => void;
};

export type UsePopoverResults<T extends HTMLElement> = {
  /**
   * Whether the popover is open.
   */
  isOpen: boolean;
  /**
   * Calls `showPopover` or `hidePopover` depending on the current state.
   */
  togglePopover: () => void;
  /**
   * Calls showPopover() on the current ref.
   */
  showPopover: () => void;
  /**
   * Calls hidePopover() on the current ref.
   */
  hidePopover: () => void;
  /**
   * Props to spread on the popover element.
   */
  popoverProps: PopoverProps<T>;
};

export function usePopover<T extends HTMLElement>({
  behaviour = 'auto',
  ref: passedRef,
  onToggle,
}: UsePopoverOptions<T>): UsePopoverResults<T> {
  const innerRef = useRef<T | null>(null);

  useEffect(() => {
    if (passedRef) {
      if ('current' in passedRef && passedRef.current) {
        innerRef.current = passedRef.current;
      }
    }
  }, [passedRef]);

  const [isOpen, setIsOpen] = useState(false);

  const handleToggleEvent = useCallback(
    (event: Event) => {
      const toggleEvent = event as ToggleEvent;
      if (toggleEvent.newState === 'open') {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
      if (onToggle) {
        onToggle(toggleEvent);
      }
    },
    [onToggle]
  );

  const showPopover = useCallback(() => {
    innerRef.current?.showPopover?.();
  }, [innerRef]);

  const hidePopover = useCallback(() => {
    innerRef.current?.hidePopover?.();
  }, [innerRef]);

  const togglePopover = useCallback(() => {
    if (isOpen) {
      hidePopover();
    } else {
      showPopover();
    }
  }, [isOpen, hidePopover, showPopover]);

  /*
    React 18 does not support the onToggle event for popovers.
    This useEffect will be removed once we adopt React 19.
  */
  useEffect(() => {
    const popoverElement = innerRef.current;
    popoverElement?.addEventListener('toggle', handleToggleEvent);
    return () => {
      popoverElement?.removeEventListener('toggle', handleToggleEvent);
    };
  }, [handleToggleEvent, innerRef]);

  const popoverProps = useMemo(() => {
    return {
      popover: behaviour,
      ref: innerRef,
    };
  }, [behaviour, innerRef]);

  return {
    isOpen,
    togglePopover,
    showPopover,
    hidePopover,
    popoverProps,
  };
}
