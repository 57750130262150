import { useCallback, useEffect, useMemo, useState } from 'react';
import { getPaginationPages, MAX_TOTAL_PAGES } from './get-pagination-pages';
import { type PaginationItem, type UsePaginationOptions } from './types';

export interface UsePaginationResults {
  /**
   * The items to render in the pagination. Can be a number or a placeholder. Use `PAGINATION_PLACEHOLDER` constant to check if the placeholder is being rendered.
   */
  pages: Array<PaginationItem>;

  /**
   * The current active page number.
   */
  activePage: number;

  /**
   * Set the active page to the provided page number.
   * @param page
   */
  setPage: (page: number) => void;

  /**
   * The props to spread on the navigation button that navigates to the previous page.
   */
  previousButtonProps: {
    disabled: boolean;
    onClick: () => void;
  };

  /**
   * The props to spread on the navigation button that navigates to the next page.
   */
  nextButtonProps: {
    disabled: boolean;
    onClick: () => void;
  };
}

const getSafePageNumber = (page: number, totalPages: number): number =>
  Math.min(Math.max(1, page), totalPages || MAX_TOTAL_PAGES);

export function usePagination({
  totalPages = 1,
  initialPage = 1,
  page,
  showLastPage = true,
  onChange,
}: UsePaginationOptions): UsePaginationResults {
  const [activePage, setActivePage] = useState(initialPage);

  useEffect(() => {
    if (page !== undefined && page !== activePage) {
      const newPage = getSafePageNumber(page, totalPages);

      setActivePage(newPage);
    }
  }, [activePage, page, totalPages]);

  const setPage = useCallback(
    (pageNumber: number) => {
      const newPage = getSafePageNumber(pageNumber, totalPages);

      setActivePage(newPage);
      onChange?.(newPage);
    },
    [onChange, totalPages]
  );

  const next = () => setPage(activePage + 1);
  const previous = () => setPage(activePage - 1);

  const pages = useMemo(
    (): Array<PaginationItem> =>
      getPaginationPages({
        totalPages,
        page: activePage,
        showLastPage,
      }),
    [activePage, showLastPage, totalPages]
  );

  return {
    pages,
    activePage,
    previousButtonProps: {
      disabled: activePage === 1,
      onClick: previous,
    },
    nextButtonProps: {
      disabled: totalPages ? activePage === totalPages : false,
      onClick: next,
    },
    setPage,
  };
}
