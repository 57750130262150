import type * as React from 'react';
import { useCallback, useEffect, useRef } from 'react';

export type UseDialogOptions = {
  /**
   * Whether the dialog is open.
   * Makes the dialog a controlled component.
   */
  open?: boolean;

  /**
   * Callback called when the dialog is opened or closed.
   */
  onToggle?: ({
    target,
    open,
    reason,
  }: {
    target: HTMLDialogElement;
    open: boolean;
    reason: string;
  }) => void;

  /**
   * Whether to reset all scrollable children before opening a dialog.
   *
   * @default true
   */
  resetScroll?: boolean;

  /**
   * Optional existing ref. If no ref is passed, one will be created.
   */
  ref?: React.ForwardedRef<HTMLDialogElement>;

  /**
   * Whether the dialog can be dismissed by clicking the backdrop or pressing the `Escape` key.
   *
   * @default true
   */
  dismissible?: boolean;
};

export type UseDialogResults = {
  /**
   * Props to spread on the `dialog` element.
   */
  dialogProps: {
    ref: React.RefObject<HTMLDialogElement>;
  };

  /**
   * Show the `dialog`, over the top of any other dialogs.
   */
  showDialog(): Promise<void>;

  /**
   * Close the `dialog`, optionally passing the `returnValue` of the `dialog`.
   */
  closeDialog(
    returnValueOrEvent?: string | React.SyntheticEvent
  ): Promise<void>;
};

export function useDialog<Options extends UseDialogOptions>(
  {
    onToggle,
    open,
    ref: passedRef,
    dismissible = true,
    resetScroll = true,
  }: Options = {} as Options
): UseDialogResults {
  const innerRef = useRef<HTMLDialogElement | null>(null);
  const dialogController = useRef<DialogController | null>(null);
  useEffect(() => {
    if (passedRef) {
      if ('current' in passedRef && passedRef.current) {
        innerRef.current = passedRef.current;
      } else if (typeof passedRef === 'function') {
        passedRef(innerRef.current);
      }
    }
    const node = innerRef.current;
    if (!node) return;
    dialogController.current = new DialogController(node, {
      dismissible,
      resetScroll,
    });
    return () => {
      if (dialogController.current) {
        dialogController.current.disconnect();
      }
    };
  }, [passedRef, dismissible, resetScroll]);

  /**
   * Calls `onToggle` when the `open` state of the native dialog changes.
   */
  useEffect(() => {
    const dialog = innerRef.current;
    if (!dialog || !onToggle) return;
    const observer = new MutationObserver((changes) => {
      for (const change of changes) {
        if (change.type === 'attributes' && change.attributeName === 'open') {
          onToggle({
            target: dialog,
            open: dialog.open,
            reason: dialog.returnValue,
          });
        }
      }
    });
    observer.observe(dialog, {
      attributes: true,
      attributeFilter: ['open'],
    });
    return () => {
      observer.disconnect();
    };
  }, [open, onToggle]);

  // Toggles the open state of the dialog element for controlled components,
  // e.g. if the `open` prop is set.
  useEffect(() => {
    const dialog = innerRef.current;
    if (typeof open !== 'boolean' || !dialog || open === dialog.open) return;
    if (dialogController.current) {
      if (open) {
        dialogController.current.showDialog();
      } else if (!open) {
        dialogController.current.closeDialog();
      }
    }
  }, [open]);

  const closeDialog = useCallback(
    async (returnValueOrEvent?: string | React.SyntheticEvent) => {
      if (dialogController.current) {
        await dialogController.current.closeDialog(returnValueOrEvent);
      }
    },
    []
  );

  const showDialog = useCallback(async () => {
    if (dialogController.current) {
      await dialogController.current.showDialog();
    }
  }, []);

  return {
    dialogProps: {
      ref: innerRef,
    },
    showDialog,
    closeDialog,
  } satisfies UseDialogResults;
}

class DialogController {
  private options = {
    resetScroll: true,
    dismissible: true,
  };
  private dialog: HTMLDialogElement;

  constructor(
    dialog: HTMLDialogElement,
    options: { resetScroll: boolean; dismissible: boolean } = {
      resetScroll: true,
      dismissible: true,
    }
  ) {
    if (!dialog) {
      throw new Error(
        '<dialog> ref is not set. Did you forget to spread `dialogProps` or pass a `ref`?'
      );
    }
    this.dialog = dialog;
    this.options = { ...this.options, ...options };
    dialog.addEventListener('cancel', this.handleCancel);
    if (this.options.dismissible) {
      dialog.addEventListener('mousedown', this.handleClick, { passive: true });
    }
  }

  disconnect() {
    this.dialog.removeEventListener('mousedown', this.handleClick);
    this.dialog.removeEventListener('cancel', this.handleCancel);
    this.dialog.removeEventListener('focus', this.handleFocus, {
      capture: true,
    });
  }

  private handleCancel = async (event: Event) => {
    event.preventDefault();
    if (this.options.dismissible) {
      await this.closeDialog('dismiss-esc');
    }
  };

  private handleClick = async (event: MouseEvent) => {
    if (this.coordinatesOutsideOfDialog(event)) {
      await this.closeDialog('dismiss-backdrop');
    }
  };

  private handleFocus = (event: FocusEvent) => {
    // In non-Safari browsers, clicking a button will first set the focus to the button
    // and then open the dialog. This puts the browser in "invisible focus state" mode,
    // since it knows the user just focused an element with a mouse, and `:focus-visible`
    // won't match.
    //
    // Safari doesn't set focus to buttons when clicked, so the browser doesn't know
    // whether it should be in "invisible focus state" mode or not.
    // See https://github.com/WICG/focus-visible/issues/257
    //
    // This leads to Safari always showing a focus-visible outline on the first button when a dialog
    // is opened, even with a click.
    //
    // If `event.relatedTarget` on the first focus event is `null`, no other element
    // had focus before the dialog was opened, meaning it wasn't triggered by a keyboard event
    // and we can safely remove the outline temporarily.
    const focusTarget = event.target as HTMLElement;
    if (!event.relatedTarget && focusTarget.matches('button:focus-visible')) {
      focusTarget.style.outline = 'transparent';
      // Another focus/blur event is fired after the dialog is opened in Safari, so we need to
      // wait a bit before adding the blur listener.
      setTimeout(() => {
        focusTarget.addEventListener(
          'blur',
          () => {
            focusTarget.style.removeProperty('outline');
          },
          { once: true }
        );
      }, 100);
    }
  };

  async closeDialog(returnValueOrEvent?: string | React.SyntheticEvent) {
    let returnValue: string | undefined;
    if (typeof returnValueOrEvent === 'string') {
      returnValue = returnValueOrEvent;
    }
    this.dialog.dataset.startingStyle = 'true';
    if (typeof this.dialog.getAnimations === 'function') {
      await Promise.allSettled(
        this.dialog?.getAnimations().map((animation) => animation.finished)
      );
    }
    this.dialog.close(returnValue);
    delete this.dialog.dataset.startingStyle;
  }

  async showDialog() {
    this.dialog.addEventListener('focus', this.handleFocus, {
      capture: true,
      once: true,
    });
    this.dialog.dataset.startingStyle = 'true';
    this.dialog.showModal();
    if (typeof this.dialog.getAnimations === 'function') {
      await Promise.allSettled(
        this.dialog.getAnimations().map((animation) => animation.finished)
      );
    }
    delete this.dialog.dataset.startingStyle;
    this.dialog.returnValue = '';
    if (this.options.resetScroll) {
      this.recursiveScrollReset();
    }
  }

  /**
   * Reset the scroll position of all scrollable children of the dialog.
   */
  private recursiveScrollReset() {
    const treeWalker = document.createTreeWalker(
      this.dialog,
      NodeFilter.SHOW_ELEMENT,
      {
        acceptNode(node: Element) {
          return node.scrollTop > 0
            ? NodeFilter.FILTER_ACCEPT
            : NodeFilter.FILTER_SKIP;
        },
      }
    );
    while (treeWalker.nextNode()) {
      (treeWalker.currentNode as HTMLElement).scrollTop = 0;
    }
  }

  /**
   * Whether the given coordinates are outside of the dialog itself, such as in the backdrop.
   *
   * "Fake" click events such as pressing Enter on a button will have 0,0 coordinates.
   */
  private coordinatesOutsideOfDialog(coords: {
    clientX: number;
    clientY: number;
  }) {
    const dialogDimensions = this.dialog.getBoundingClientRect();
    return (
      coords.clientX > 0 &&
      coords.clientY > 0 &&
      (coords.clientX < dialogDimensions.left ||
        coords.clientX > dialogDimensions.right ||
        coords.clientY < dialogDimensions.top ||
        coords.clientY > dialogDimensions.bottom)
    );
  }
}
