import { useEffect, useMemo } from 'react';
import {
  autoUpdate,
  flip,
  offset,
  type Placement,
  type ReferenceType,
  useFloating,
} from '@floating-ui/react-dom';
import { type PopoverBehaviour, usePopover } from './popover';

export { type Placement };

/**
 * Offset from the anchor element: 4px.
 */
const DEFAULT_OFFSET = 4;

export type UseAnchoredPopoverOptions = {
  /**
   * The behaviour of the popover.
   *
   * @default 'auto'
   */
  behaviour?: PopoverBehaviour;
  /**
   * The placement of the popover.
   *
   * @default 'bottom-start'
   */
  placement?: Placement;
  /**
   * Callback triggered on the popover ToggleEvent.
   */
  onToggle?: (event: ToggleEvent) => void;
};

export type UseAnchoredPopoverResults = {
  /**
   * Whether the popover is open.
   */
  isOpen: boolean;
  /**
   * Calls `showPopover` or `hidePopover` depending on the current state.
   */
  togglePopover: () => void;
  /**
   * Calls showPopover() on the current ref.
   */
  showPopover: () => void;
  /**
   * Calls hidePopover() on the current ref.
   */
  hidePopover: () => void;
  /**
   * Props to spread on the anchor element.
   */
  anchorProps: {
    ref: (node: ReferenceType | null) => void;
  };
  /**
   * Props to spread on the anchor element.
   */
  popoverProps: {
    popover: PopoverBehaviour;
    ref: (node: HTMLElement | null) => void;
    style: {
      transform: string;
    };
  };
  elements: {
    reference: ReferenceType | null;
    floating: HTMLElement | null;
  };
};

export function useAnchoredPopover({
  placement,
  behaviour = 'auto',
  onToggle,
}: UseAnchoredPopoverOptions): UseAnchoredPopoverResults {
  const { refs, update, elements, x, y } = useFloating({
    strategy: 'fixed',
    placement: placement,
    middleware: [offset(DEFAULT_OFFSET), flip()],
  });

  const { isOpen, togglePopover, popoverProps, hidePopover, showPopover } =
    usePopover({
      ref: refs.floating,
      behaviour,
      onToggle,
    });

  /**
   * This is needed in order to only update the position when the popover is open
   *
   * @see https://floating-ui.com/docs/autoUpdate#usage
   */
  useEffect(() => {
    if (isOpen && elements.reference && elements.floating) {
      const cleanup = autoUpdate(elements.reference, elements.floating, update);
      return cleanup;
    }
  }, [isOpen, elements, update]);

  const _showPopover = () => {
    showPopover();
    /**
     * Calls update() on the next repaint to ensure that the popover is positioned correctly.
     * This removes flickering on the first open if reduce-motion is enabled.
     */
    requestAnimationFrame(() => {
      update();
    });
  };

  const anchorProps = useMemo(() => {
    return {
      ref: refs.setReference,
    };
  }, [refs.setReference]);

  const anchoredPopoverProps = useMemo(() => {
    return {
      ...popoverProps,
      ref: refs.setFloating,
      style: {
        transform: `translateX(${x}px) translateY(${y}px)`,
      },
    };
  }, [popoverProps, refs.setFloating, x, y]);

  return {
    elements,
    anchorProps,
    popoverProps: anchoredPopoverProps,
    togglePopover,
    showPopover: _showPopover,
    hidePopover,
    isOpen,
  };
}
