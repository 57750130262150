export const ICON_CDN_VERSION = 'v4';

export type IconName = (typeof ICON_NAMES)[number];
export type BrandedIconName = (typeof BRANDED_ICON_NAMES)[number];

export const ICON_NAMES = [
  '2x-circled',
  'abs',
  'abs-brake',
  'ac',
  'address-book',
  'air-distribution-face',
  'air-distribution-feet',
  'air-distribution-windscreen',
  'air-filter',
  'air-filter-particles',
  'airbag',
  'airbag-off',
  'airbag-on',
  'alcohol-lock',
  'ambient-light',
  'amp-meter',
  'animal',
  'aqi-circled',
  'ar',
  'arrow-back',
  'arrow-back-forward',
  'arrow-curved-back',
  'arrow-curved-forward',
  'arrow-curved-left',
  'arrow-deflected-up',
  'arrow-down',
  'arrow-down-back',
  'arrow-down-circled',
  'arrow-down-forward',
  'arrow-forward',
  'arrow-forward-car-side',
  'arrow-forward-car-side-off',
  'arrow-forward-split',
  'arrow-merging',
  'arrow-up',
  'arrow-up-back',
  'arrow-up-forward',
  'arrow-up-line',
  'arrows-back-forward',
  'arrows-down-up',
  'arrows-multidirectional',
  'arrows-multidirectional-car',
  'arrows-split',
  'arrows-swap',
  'arrows-towbar',
  'asterisk',
  'audio-book',
  'audio-wave',
  'auto',
  'auto-brake',
  'awd',
  'awd-arrows',
  'awd-car',
  'backspace',
  'band-aid',
  'bank',
  'basketball',
  'battery-10',
  'battery-100',
  'battery-20',
  'battery-30',
  'battery-40',
  'battery-50',
  'battery-60',
  'battery-70',
  'battery-80',
  'battery-90',
  'battery-frame',
  'battery-frame-dotted',
  'battery-frame-standing',
  'battery-frame-standing-car',
  'battery-frame-standing-minus',
  'battery-frame-standing-plus',
  'battery-standing-10',
  'battery-standing-100',
  'battery-standing-20',
  'battery-standing-30',
  'battery-standing-40',
  'battery-standing-50',
  'battery-standing-60',
  'battery-standing-70',
  'battery-standing-80',
  'battery-standing-90',
  'bed',
  'bell',
  'bell-phone',
  'bell-slashed',
  'bell-speedo',
  'bell-speedo-slashed',
  'bending-lights',
  'bill',
  'bills',
  'bills-circle-backwards',
  'bills-circle-forward',
  'bills-loop-forward',
  'bills-slashed',
  'blis',
  'blocked',
  'bluetooth',
  'bluetooth-connected',
  'bluetooth-connections',
  'bolt',
  'book',
  'book-with-text',
  'bookmark',
  'books',
  'box-list-papers',
  'brake',
  'brake-fluid',
  'brake-light-crossed',
  'brake-pedal',
  'brake-pedal-auto',
  'brake-us',
  'briefcase',
  'broken-glass',
  'broom-and-bucket',
  'browse',
  'bug',
  'bulb',
  'calendar',
  'calendar-1-dot',
  'calendar-3-dots',
  'calendar-7-dots',
  'calendar-date',
  'calligraphy-pen',
  'camera',
  'camera-front',
  'camera-left-right',
  'camera-rear',
  'camera-rotate',
  'camera-side-left',
  'camera-slashed',
  'cancel-route',
  'car',
  'car-batteries',
  'car-battery',
  'car-framed',
  'car-moving',
  'car-overtake-left',
  'car-overtake-right',
  'car-pin',
  'car-rear',
  'car-sensors',
  'car-shadow',
  'car-side-arrow-inside',
  'car-side-profile',
  'car-side-profile-cropped',
  'card-charging',
  'card-key',
  'card-key-connected',
  'cars',
  'charger-ccs',
  'charger-cn',
  'charger-cn-eu',
  'charger-eu',
  'charger-jp',
  'charger-jp-na',
  'charger-na',
  'charger-nacs',
  'charger-wireless',
  'charging-battery',
  'charging-battery-standing',
  'charging-note',
  'checked-calendar',
  'checklist',
  'checklist-car',
  'checklist-paper',
  'checkmark',
  'checkmark-circled',
  'checkmark-device',
  'checkmark-pin',
  'chevron-back',
  'chevron-down',
  'chevron-forward',
  'chevron-up',
  'child',
  'child-lock-locked',
  'child-lock-unlocked',
  'chip-exclamation-mark',
  'chip-question-mark',
  'circle',
  'circle-arrow',
  'circle-backwards',
  'circle-backwards-15',
  'circle-backwards-credit-card',
  'circle-forward',
  'circle-forward-15',
  'circle-forward-a',
  'circle-forward-cropped',
  'circled-a',
  'circled-b',
  'circled-thumbs-up',
  'city',
  'climate-manikin',
  'clipboard',
  'clock',
  'clock-car',
  'clock-circle-forward',
  'clock-circle-forward-24h',
  'clock-circle-forward-large',
  'clock-circled',
  'clock-dotted',
  'cloud',
  'cloud-and-leaf',
  'cloud-connect',
  'cloud-download-battery',
  'cloud-slashed',
  'co2',
  'cobalt',
  'cobalt-search',
  'code',
  'coffee-cup',
  'cog',
  'cog-car',
  'cog-f',
  'cog-folder',
  'cog-o',
  'cog-p',
  'cog-profile',
  'cog-r',
  'cogs',
  'coins',
  'color-palette',
  'compass',
  'computer',
  'conference-table',
  'connected-stethoscope',
  'contrast',
  'controller',
  'coolant',
  'credit-card',
  'credit-cards',
  'crop',
  'crosshair',
  'cta',
  'cta-slashed',
  'cube-3d',
  'cube-3d-divided',
  'currency-usd',
  'curved-road-car',
  'defroster-front',
  'defroster-rear',
  'delivery-truck',
  'desks',
  'device',
  'diagnostics',
  'diamond',
  'diamond-cog',
  'diamond-sparkling',
  'digital-key',
  'document',
  'download',
  'download-cloud',
  'download-paper',
  'download-square',
  'dresser',
  'drop',
  'drop-minus',
  'drops-car',
  'eco',
  'electric-motor',
  'electric-windscreen',
  'elevator',
  'ema-circled',
  'email',
  'email-open',
  'engine',
  'engine-coolant-temp',
  'enter',
  'equal',
  'eraser',
  'esc',
  'esc-off',
  'esc-slashed',
  'evasive-manoeuvre',
  'evasive-manoeuvre-assist',
  'exclamation-battery-standing',
  'exclamation-light-bulb',
  'exclamation-mark',
  'exclamation-mark-brake',
  'exclamation-mark-car',
  'exclamation-mark-circled',
  'external-link',
  'eye',
  'eye-slashed',
  'fabric',
  'fan',
  'fan-seat-left',
  'fan-seat-left-auto',
  'fan-seat-right',
  'fan-seat-right-auto',
  'fan-slashed',
  'fast-charger',
  'ferry',
  'ferry-and-train',
  'file-csv',
  'file-txt',
  'file-xls',
  'filter',
  'fire',
  'flower',
  'foglight-front',
  'foglight-rear',
  'folder',
  'fork-and-knife',
  'forward-collision-warning',
  'fridge',
  'front-sensor',
  'fuel-pump',
  'fuel-pump-circled',
  'fuel-pump-handle',
  'fuel-pump-hybrid',
  'fwd',
  'garage-car',
  'garden-tools',
  'ghost',
  'gift',
  'globe',
  'glovebox',
  'golf-bag',
  'graph-average',
  'graph-bars',
  'graph-bars-speedo',
  'graph-lines',
  'group',
  'h-brake',
  'hands-steering-wheel',
  'handshake',
  'hangtag',
  'hashtag',
  'headset',
  'heart',
  'heart-hands',
  'heat',
  'heat-seat-left',
  'heat-seat-left-auto',
  'heat-seat-right',
  'heat-seat-right-auto',
  'heat-steering-wheel',
  'heat-steering-wheel-auto',
  'heating',
  'high-beam',
  'high-beam-auto',
  'highway',
  'home',
  'home-charger',
  'home-connected',
  'home-low-beam',
  'home-work',
  'honk-and-flash',
  'hood',
  'horn',
  'hotspot',
  'hourglass',
  'hud',
  'hud-move',
  'hud-rotate',
  'human',
  'human-framed',
  'i-on-book',
  'id-card',
  'id-card-slashed',
  'image',
  'image-framed',
  'indicator-arrow-left',
  'indicator-arrow-right',
  'indicators',
  'indicators-trailer',
  'info-circled',
  'info-squared',
  'ion',
  'jerrycan',
  'key',
  'key-fob',
  'key-fob-connected',
  'key-loop-forward',
  'key-speed-limit',
  'keyboard-down',
  'keypad',
  'kitchen',
  'laptop',
  'lawnmower',
  'layers',
  'lca-left',
  'lca-right',
  'ldw-left',
  'ldw-right',
  'leaf',
  'leaf-circle-forward',
  'leaf-loop-backward',
  'leaf-loop-forward',
  'leaf-simple',
  'leather',
  'leave-the-car',
  'legal-hammer',
  'light-bulb',
  'light-left',
  'light-left-warning',
  'light-right',
  'light-right-warning',
  'light-trailer',
  'lightning-bolt',
  'lightning-bolt-circled',
  'lightning-bolt-circled-staggered',
  'lightning-bolt-connected',
  'lights',
  'lights-adjust',
  'lights-adjust-down',
  'lights-adjust-up',
  'lim',
  'link',
  'linked',
  'linked-broken',
  'list',
  'list-checked',
  'list-square',
  'list-unchecked',
  'lka',
  'lka-intervention-left',
  'lka-intervention-right',
  'lka-off',
  'lka-slashed',
  'lock',
  'lock-batteries',
  'lock-car',
  'lock-car-side-profile',
  'lock-open',
  'lock-shield',
  'lock-steering-wheel',
  'lock-wifi',
  'loop-backwards',
  'loop-backwards-dotted',
  'loop-backwards-rectangle',
  'loop-backwards-rounded',
  'loop-backwards-square',
  'loop-backwards-square-dotted',
  'loop-forward',
  'loop-forward-360',
  'loop-forward-car',
  'loop-forward-cube',
  'low-beam',
  'mail',
  'manual-transmission',
  'manual-tuning',
  'map',
  'map-pin',
  'map-pin-lightning-bolt',
  'mask',
  'maximise',
  'megaphone',
  'memory-card',
  'menu',
  'merge',
  'message-phone',
  'microphone',
  'microphone-slashed',
  'microphone-speech-bubble',
  'minimise',
  'minus',
  'minus-circled',
  'mirror-left',
  'mirror-left-adjust',
  'mirror-left-fold',
  'mirror-right',
  'mirror-right-adjust',
  'mirror-right-fold',
  'mobile-phone',
  'mobile-phone-connected',
  'moon',
  'moon-phase',
  'moon-roof',
  'mountain',
  'mountains',
  'mountains-road',
  'mountains-straight-road',
  'navigation-arrow',
  'navigation-arrow-circled',
  'newspaper',
  'next',
  'northern-lights',
  'note',
  'note-usb',
  'now-playing',
  'odo',
  'off',
  'office-building',
  'office-chair',
  'office-supplies',
  'oil',
  'oil-level',
  'ok',
  'on',
  'package',
  'paper-clip',
  'paper-plane',
  'papers',
  'park-assist-camera',
  'parking',
  'parking-assist-phone',
  'parking-brake',
  'parking-brake-us',
  'parking-circled',
  'parking-sensors',
  'parking-squared',
  'parking-steering-wheel',
  'pause',
  'pause-phone',
  'pedestrian-collision',
  'pegman',
  'pen',
  'pen-circled',
  'pen-paper',
  'people-square',
  'percentage-circled',
  'phone',
  'phone-down',
  'phone-down-arrow',
  'phone-shield',
  'photo',
  'pin-car',
  'pin-road-ahead',
  'pin-road-left',
  'pin-road-right',
  'planet-hand',
  'play',
  'play-circled',
  'plug',
  'plus',
  'plus-car',
  'plus-circle-forward',
  'plus-circled',
  'plus-circled-square-dotted',
  'plus-credit-card',
  'plus-mobile-phone',
  'plus-phone',
  'plus-search',
  'podium',
  'previous',
  'printer',
  'profile',
  'profile-arrows-in',
  'profile-arrows-out',
  'profile-car',
  'profile-circled',
  'profile-stars-auto',
  'profiles',
  'projector',
  'pump-charging',
  'qr-code-scanner',
  'question-mark',
  'question-mark-circled',
  'question-mark-circled-dotted',
  'queue',
  'r-brake',
  'r-brake-slashed',
  'radio',
  'rain',
  'rear-window-wiper',
  'rear-window-wiper-intermit',
  'receipt',
  'receipt-slashed',
  'rectangle-arrow-out',
  'rectangle-portrait',
  'rectangle-portrait-hashed',
  'recycle',
  'remote-start',
  'restroom',
  'retailer',
  'ribbon',
  'road',
  'road-slashed',
  'roof-car',
  'rose',
  'rotate-cube-3d',
  'route',
  'sand-dune',
  'satellite',
  'satellite-radio',
  'scale',
  'screen-clean',
  'screen-landscape',
  'screen-portrait',
  'scribble',
  'search',
  'search-car',
  'seat',
  'seat-4',
  'seat-7',
  'seat-belt',
  'seat-belt-adjust',
  'seat-bolster',
  'seat-cushion',
  'seat-fold',
  'seat-headrest-fold',
  'seat-lean',
  'seat-lumbar',
  'seat-massage',
  'seat-right',
  'seat-right-bolster',
  'seat-right-cushion',
  'seat-right-lumbar',
  'seat-right-massage',
  'seat-third-row',
  'sensor-car-inside',
  'set-speed-default',
  'set-speed-resume',
  'share',
  'shield',
  'shield-checked',
  'shield-checked-car',
  'shield-device',
  'shield-plus',
  'shift',
  'shift-lock',
  'shopping-bag',
  'shuffle',
  'signed-paper',
  'smart-watch',
  'smiley-happy',
  'smiley-neutral',
  'smiley-sad',
  'smiley-slightly-happy',
  'smiley-slightly-sad',
  'snowflake',
  'snowflake-batteries',
  'snowflake-battery',
  'snowflake-sun',
  'sos-call',
  'sound-bar',
  'space',
  'speaker',
  'speech-bubble',
  'speech-bubble-text',
  'speech-bubble-three-dots',
  'speech-bubbles',
  'speedo',
  'speedo-car',
  'speedo-max',
  'speedo-set',
  'spray-bottle',
  'square',
  'square-circled',
  'square-grid',
  'stack',
  'stacked-squares',
  'stacked-squares-car',
  'star',
  'star-circled',
  'star-half',
  'start',
  'steering-stacked',
  'steering-wheel',
  'steering-wheel-a-left',
  'steering-wheel-a-right',
  'steering-wheel-seat-left',
  'steering-wheel-seat-left-auto',
  'steering-wheel-seat-right',
  'steering-wheel-seat-right-auto',
  'steering-wheel-shield',
  'step-backward',
  'step-downwards',
  'step-forward',
  'step-upwards',
  'stopwatch',
  'storage',
  'stroller',
  'structure',
  'suitcase',
  'sun',
  'sun-and-cloud',
  'sunset',
  'superhuman-light-bulb',
  'suspension',
  'swap-car',
  'switch-phone',
  'switch-view',
  'table',
  'temperature-cog',
  'tent',
  'three-dots',
  'thumbs-down',
  'thumbs-up',
  'timer',
  'tire',
  'tools',
  'touch',
  'tow-truck',
  'tpms',
  'tpms-tc',
  'trailer',
  'train',
  'trash-can',
  'trees',
  'triangle',
  'triangle-back',
  'triangle-down',
  'triangle-forward',
  'triangle-up',
  'trip-1',
  'trip-2',
  'trip-ta',
  'trip-tm',
  'trophy',
  'trunk-arrow',
  'trunk-open',
  'trunk-space',
  'turn-right-sign',
  'turtle',
  'tv',
  'twilight',
  'upload',
  'usb',
  'usb-symbol',
  'usd-circle-forward',
  'usd-coin',
  'video',
  'video-framed',
  'voice-assistant-speaking',
  'volume',
  'volume-car',
  'volume-car-off',
  'volume-off',
  'volume-off-speech-bubble',
  'volume-speech-bubble',
  'volvo-cars-app',
  'volvo-iron-mark',
  'wall-charger',
  'wallet',
  'warning',
  'warning-car',
  'warning-car-front',
  'warning-car-sensors',
  'warning-cars',
  'warning-coolant',
  'warning-door-sensor',
  'warning-eye',
  'warning-plug',
  'warning-speedo',
  'warning-speedo-off',
  'warning-towbar',
  'warning-triangle',
  'water-windscreen-wiper',
  'wheel',
  'wheel-moving',
  'wifi',
  'window-x',
  'windscreen-washer',
  'windscreen-wiper',
  'workstation',
  'wrench',
  'wrench-and-screwdriver',
  'wrench-house',
  'wrench-papers',
  'x',
  'x-circled',
  'x-search',
] as const;

export const BRANDED_ICON_NAMES = [
  'amazon-key',
  'american-express',
  'apple-ar',
  'apple-carplay',
  'blogger',
  'facebook',
  'figma',
  'google',
  'google-assistant',
  'google-maps',
  'google-play',
  'instagram',
  'line',
  'linkedin',
  'mastercard',
  'notion',
  'ovo',
  'pinterest',
  'salesforce',
  'threads',
  'visa',
  'vkontakte',
  'wechat',
  'weibo',
  'whatsapp',
  'x-app',
  'youtube',
] as const;
