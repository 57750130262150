import { useEffect, useRef } from 'react';

/**
 * Custom hook that creates an interval that invokes a callback function at a specified delay using the ```setInterval``` API.
 */
export function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef(callback);

  // Update the saved callback reference whenever the callback changes.
  // This ensures that the most recent callback is used without needing to reset the interval.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval with the specified delay.
  useEffect(() => {
    // Exit early if no delay is provided (null indicates no interval).
    if (delay === null) {
      return;
    }

    // Create a recurring timer that calls the saved callback at the specified interval.
    const id = setInterval(() => {
      savedCallback.current();
    }, delay);

    // Clean up the interval when the component unmounts or delay changes.
    return () => {
      clearInterval(id);
    };
  }, [delay]);
}
