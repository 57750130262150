import { useId, useState } from 'react';

/**
 * A tab group is a collection of tabs and tab panels. The tabs are the
 * navigational elements, and the tab panels are the content associated with
 * each tab.
 *
 * The state holds the information needed to render the tabs and panels.
 */
export type TabGroupState = {
  selectedIndex: number;
  getItem(index: number): {
    tabId: string;
    tabPanelId: string;
  };
};

export type UseTabListOptions = {
  /**
   * The id of the tab list. If not provided, a unique id will be generated.
   */
  id?: string;

  /**
   * Makes the tab list a controlled component with the provided index.
   */
  selectedIndex?: number;

  /**
   * The tab that's selected by default in an uncontrolled component.
   *
   * @default 0
   */
  defaultSelectedIndex?: number;

  /**
   * The orientation of the tab list.
   */
  orientation?: 'horizontal' | 'vertical';

  /**
   * Fires when the selected index changes.
   */
  onChange?: (index: number) => void;
};

export type UseTabListResults = {
  /**
   * The index of the selected tab.
   */
  selectedIndex: number;

  /**
   * The props to spread on the element wrapping the individual tabs.
   */
  tabListProps: {
    id: string;
    role: 'tablist';
    onKeyDown: (event: React.KeyboardEvent) => void;
    onClick: (event: React.MouseEvent) => void;
  };

  /**
   * The state used to render the tabs and panels.
   */
  tabGroupState: TabGroupState;
};

/**
 * Maintains the state controlling the tabs and tab panels.
 */
export function useTabList({
  id,
  onChange,
  orientation = 'horizontal',
  selectedIndex: controlledSelectedIndex,
  defaultSelectedIndex,
}: UseTabListOptions = {}): UseTabListResults {
  const generatedId = useId();
  const listId = id || generatedId;
  const [uncontrolledSelectedIndex, setSelectedIndex] = useState(
    defaultSelectedIndex || 0
  );
  const selectedIndex =
    typeof controlledSelectedIndex === 'undefined'
      ? uncontrolledSelectedIndex
      : controlledSelectedIndex;
  return {
    selectedIndex,
    tabListProps: {
      id: listId,
      role: 'tablist',
      onKeyDown: (event: React.KeyboardEvent) => {
        const el = event.target as HTMLElement;
        if (el.getAttribute('role') !== 'tab') {
          return;
        }
        const tabs =
          event.currentTarget.querySelectorAll<HTMLElement>('[role=tab]') || [];
        const index = Array.from(tabs).indexOf(el);
        const isRtl =
          orientation === 'horizontal' &&
          window.getComputedStyle(el).direction === 'rtl';
        const first = 0;
        const last = tabs.length - 1;
        const back = index === first ? last : index - 1;
        const forward = index === last ? first : index + 1;
        const keyToNewIndex = {
          Home: first,
          End: last,
          ArrowUp: forward,
          ArrowDown: back,
          ArrowLeft: isRtl ? forward : back,
          ArrowRight: isRtl ? back : forward,
        };
        if (!Object.prototype.hasOwnProperty.call(keyToNewIndex, event.key)) {
          return;
        }
        event.preventDefault();
        const newIndex = keyToNewIndex[event.key as keyof typeof keyToNewIndex];
        tabs[newIndex].focus();
        setSelectedIndex(newIndex);
        onChange && onChange(index);
      },
      onClick: (event: React.MouseEvent) => {
        const tab = (event.target as HTMLElement).closest(
          '[role=tab]'
        ) as HTMLElement;
        if (!tab) {
          return;
        }
        event.preventDefault();
        const tabs =
          event.currentTarget.querySelectorAll<HTMLElement>('[role=tab]') || [];
        const index = Array.from(tabs).indexOf(tab);
        if (index !== -1 && index !== selectedIndex) {
          setSelectedIndex(index);
          onChange && onChange(index);
        }
      },
    },
    tabGroupState: {
      selectedIndex,
      getItem(index: number) {
        return {
          tabId: listId + 'tab' + index,
          tabPanelId: listId + 'panel' + index,
        };
      },
    },
  };
}

export type UseTabPanelOptions = {
  /**
   * The state from the associated tab list.
   */
  state: TabGroupState;

  /**
   * The index of the tab panel.
   */
  index: number;
};

export type UseTabPanelResults = {
  /**
   * Whether the tab panel's associated tab is selected.
   */
  isSelected: boolean;
  /**
   * The props to spread on the tab panel.
   */
  tabPanelProps: {
    id: string;
    key: string;
    role: 'tabpanel';
    'aria-labelledby': string;
    'aria-hidden'?: boolean;
    hidden?: boolean;
  };
};

/**
 * The tab panel is the content associated with a tab.
 *
 * Tab panels can be rendered conditionally based on the selected tab or
 * rendered all at once and hidden when not selected.
 */
export function useTabPanel({
  state,
  index,
}: UseTabPanelOptions): UseTabPanelResults {
  const isSelected = state.selectedIndex === index;
  const item = state.getItem(index);
  return {
    isSelected,
    tabPanelProps: {
      id: item.tabPanelId,
      key: item.tabPanelId,
      role: 'tabpanel',
      'aria-labelledby': item.tabId,
      'aria-hidden': isSelected ? undefined : true,
      hidden: !isSelected,
    },
  };
}

export type UseTabOptions = {
  /**
   * The state from the associated tab list.
   */
  state: TabGroupState;

  /**
   * The index of the tab.
   */
  index: number;
};

export type UseTabResults = {
  /**
   * Whether the tab is selected.
   */
  isSelected: boolean;

  /**
   * The props to spread on the tab.
   */
  tabProps: {
    id: string;
    key: string;
    role: 'tab';
    'aria-selected': boolean;
    'aria-controls'?: string;
    tabIndex: number;
  };
};

/**
 * The tab is the navigational element that controls the visibility of the
 * associated tab panel.
 *
 * Tabs must be rendered within a tab list.
 */
export function useTab({ state, index }: UseTabOptions): UseTabResults {
  const isSelected = state.selectedIndex === index;
  const item = state.getItem(index);
  return {
    isSelected,
    tabProps: {
      id: item.tabId,
      key: item.tabId,
      role: 'tab',
      // Non-selected tabs may or may not exist in the DOM, so only refer to the
      // panel if the tab is selected.
      'aria-controls': isSelected ? item.tabPanelId : undefined,
      'aria-selected': isSelected,
      tabIndex: state.selectedIndex === index ? 0 : -1,
    },
  };
}
