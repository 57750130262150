import { forwardRef } from 'react';
import { cssMerge } from '@volvo-cars/css/utils';
import { ErrorMessage } from './error-message';
import { Hint } from './hint';
import { type BaseInputProps, type GlobalHTMLAttributes } from './types';
import { useIds } from './use-ids';

interface BaseCheckboxProps extends GlobalHTMLAttributes, BaseInputProps {
  /**
   * @deprecated `isValid` from VCC UI `useField` hook is not explicitly supported, but included for ease of migration until there is a convenient migration path for form validation
   */
  isValid?: boolean;
  /**
   * The name of the input to use when submitting the form.
   */
  name: string;

  /**
   * Concise label for the checkbox.
   *
   * Labels should not contain HTML, add any required links in the `hint` instead.
   */
  label: string;

  /**
   * The value of the checkbox that will be submitted with the form data.
   *
   * @default on
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/checkbox#value
   */
  value?: string;

  /**
   * Makes the checkbox required.
   */
  required?: boolean;

  /**
   * Set the error message of a checkbox and mark it invalid.
   */
  errorMessage?: string;

  /**
   * Force the checkbox to be invalid.
   *
   * @default false
   */
  'aria-invalid'?: boolean;
}

type ControlledProps = {
  /**
   * Whether the checkbox is checked.
   * Makes the input controlled.
   */
  checked: boolean;

  /**
   * Fires when the input is checked or unchecked.
   */
  onChange: React.ChangeEventHandler<HTMLInputElement>;

  defaultChecked?: never;
};

type UncontrolledProps = {
  /**
   * Whether an (uncontrolled) checkbox is checked by default.
   */
  defaultChecked?: boolean;

  /**
   * Fires when the input is checked or unchecked.
   */
  onChange?: React.ChangeEventHandler<HTMLInputElement>;

  checked?: never;
};

export type CheckboxUncontrolledProps = BaseCheckboxProps & UncontrolledProps;
export type CheckboxControlledProps = BaseCheckboxProps & ControlledProps;
export type CheckboxProps = BaseCheckboxProps &
  (ControlledProps | UncontrolledProps);

/**
 * Checkboxes should be used for selecting one or more of many options, or as a toggle with a favourable option.
 */
export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  function Checkbox(
    {
      label,
      id,
      hint,
      hidden,
      dir,
      lang,
      translate,
      slot,
      style,
      errorMessage: errorMessageProp,
      isValid,
      className,
      ...props
    }: CheckboxProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) {
    const { inputId, hintId, errorId } = useIds(id);

    // Hide error message and error state when input is disabled
    const errorMessage = !props.disabled ? errorMessageProp : undefined;
    const ariaInvalid =
      props['aria-invalid'] || isValid === false || !!errorMessage;
    return (
      <div
        className={cssMerge('flex-row', className)}
        hidden={hidden}
        dir={dir}
        lang={lang}
        translate={translate}
        slot={slot}
        style={style}
      >
        <input
          {...props}
          ref={ref}
          type="checkbox"
          id={inputId}
          aria-invalid={ariaInvalid ? true : undefined}
          aria-errormessage={errorMessage ? errorId : undefined}
          aria-describedby={hint ? hintId : undefined}
        />
        <div className="flex-col stack-4 ml-8">
          <label htmlFor={inputId} className="pl-8 -ml-8">
            {label}
          </label>
          <ErrorMessage errorMessage={errorMessage} id={errorId} />
          {hint && <Hint id={hintId}>{hint}</Hint>}
        </div>
      </div>
    );
  }
);
