import { forwardRef } from 'react';
import { cssMerge } from '@volvo-cars/css/utils';
import { getBrandedIconSrc } from './config';
import { type DeprecatedBrandedIconName } from './icons.deprecated';
import { type BrandedIconName } from './icons.generated';

export type BrandedIconProps = {
  /**
   * The name of the icon.
   */
  icon: BrandedIconName;

  /**
   * The size of the icon in pixels.
   */
  size: 24 | 32 | 40;

  /**
   * Custom class name, merged with existing classes.
   */
  className?: string;

  /**
   * Alternate text.
   *
   * May be omitted if the alternate text is already present,
   * such as in a button that already has a label.
   */
  alt?: string;

  /**
   * Indicates how the browser should load the image.
   *
   * Prefer `lazy` for images below the fold or not immediately visible.
   */
  loading?: 'eager' | 'lazy';

  hidden?: boolean;
  id?: string;
  title?: string;
  dir?: string;
  lang?: string;
  slot?: string;
  style?: React.CSSProperties;

  onAnimationEnd?: React.AnimationEventHandler<HTMLImageElement>;
  onAnimationStart?: React.AnimationEventHandler<HTMLImageElement>;
  onTransitionEnd?: React.TransitionEventHandler<HTMLImageElement>;
};

type DeprecatedBrandedIconProps = Omit<BrandedIconProps, 'icon'> & {
  /**
   * @deprecated Replace any deprecated icons.
   */
  icon: DeprecatedBrandedIconName | BrandedIconName;
};

type BrandedIconComponent = {
  (
    props: BrandedIconProps & { ref?: React.ForwardedRef<HTMLImageElement> },
    ref: React.ForwardedRef<HTMLImageElement>
  ): React.ReactNode;
  (
    props: DeprecatedBrandedIconProps & {
      ref?: React.ForwardedRef<HTMLImageElement>;
    },
    ref: React.ForwardedRef<HTMLImageElement>
  ): React.ReactNode;
};

/**
 * BrandedIcon renders an `img` with a src on the volvocars.com CDN with icons
 * representing Partners and Services.
 */

export const BrandedIcon: BrandedIconComponent = forwardRef<
  HTMLImageElement,
  BrandedIconProps | DeprecatedBrandedIconProps
>(function BrandedIcon({ alt, icon, size, ...props }, ref) {
  const src = getBrandedIconSrc(icon);
  const className = cssMerge('icon-sprite', props.className);
  return (
    <img
      {...props}
      ref={ref}
      className={className}
      width={size}
      height={size}
      src={src}
      role={alt ? undefined : 'presentation'}
      alt={alt || ''}
    />
  );
});
