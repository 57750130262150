import { useLayoutEffect as _useLayoutEffect, useEffect, useRef } from 'react';

const useLayoutEffect =
  typeof window === 'undefined' ? useEffect : _useLayoutEffect;

export interface UseAnimatedDetailsOptions {
  /**
   * The ref of the details element to animate.
   */
  ref?: React.ForwardedRef<HTMLDetailsElement>;

  /**
   * Whether the details element should be open.
   * Makes the details element a controlled component.
   */
  open?: boolean;

  /**
   * Callback called when the details element is toggled.
   */
  onToggle?: (open: boolean) => void;

  /**
   * Callback called when the animation is finished.
   */
  onAnimationFinish?: (open: boolean) => void;
}

export interface UseAnimatedDetailsResults {
  ref: React.Ref<HTMLDetailsElement>;
}

export function useAnimatedDetails({
  ref: passedRef,
  open,
  onToggle,
  onAnimationFinish,
}: UseAnimatedDetailsOptions = {}): UseAnimatedDetailsResults {
  const innerRef = useRef<HTMLDetailsElement | null>(null);
  const animatedDetails = useRef<AnimatedDetails | null>(null);
  const isControlled = typeof open === 'boolean';
  const isFirstRender = useRef(true);

  useEffect(() => {
    const node = innerRef.current;
    if (!node) return;
    if (typeof passedRef === 'function') {
      passedRef(node);
    } else if (passedRef) {
      passedRef.current = node;
    }
    if (enableAnimation()) {
      animatedDetails.current = new AnimatedDetails(node, isControlled);
    }
    return () => {
      if (animatedDetails.current) {
        animatedDetails.current.disconnect();
      }
    };
  }, [passedRef, isControlled]);

  // Toggles the open state of the details element for controlled components,
  // e.g. if the `open` prop is set.
  useLayoutEffect(() => {
    const node = innerRef.current;
    if (typeof open !== 'boolean' || !node || open === node.open) return;
    if (animatedDetails.current) {
      if (open) {
        // If this is the first render, we don't animate the opening.
        if (!isFirstRender.current) {
          animatedDetails.current.open();
        } else {
          node.open = true;
        }
      } else if (!open) {
        animatedDetails.current.close();
      }
    } else {
      node.open = open;
    }
    isFirstRender.current = false;
  }, [open]);

  useEffect(() => {
    const details = innerRef.current;
    if (!details) return;

    if (animatedDetails.current) {
      // By using properties on the instance instead of passing these
      // to the constructor we can avoid re-creating the instance when
      // the callbacks change. There's also no need to add and remove
      // event listeners on the DOM nodes from React.
      animatedDetails.current.onAnimationFinish = onAnimationFinish;
    }

    if (onToggle) {
      let prevOpen = details.open;
      const observer = new MutationObserver((changes) => {
        for (const change of changes) {
          if (change.attributeName === 'open' && details.open !== prevOpen) {
            onToggle(details.open);
            prevOpen = details.open;
          }
        }
      });
      observer.observe(details, {
        attributes: true,
        attributeFilter: ['open'],
      });
      return () => {
        observer.disconnect();
      };
    }
  }, [onAnimationFinish, onToggle, open]);

  return { ref: innerRef };
}

/**
 * Animation related methods operating on a HTMLDetailsElement.
 */
class AnimatedDetails {
  private initialOverflow?: string;
  private state: 'idle' | 'closing' | 'opening' = 'idle';
  private animation: Animation | null = null;
  private closedHeight: number = 0;
  private openedHeight: number = 0;
  private disconnectObservers?: () => void;
  private details: HTMLDetailsElement;
  private summary: HTMLElement | null;
  public controlled?: boolean;
  public onAnimationFinish?: (open: boolean) => void;

  constructor(details: HTMLDetailsElement, controlled?: boolean) {
    this.details = details;
    this.summary = details.querySelector('summary');
    this.controlled = controlled;
    this.initialOverflow = this.details.style.overflow;
    if (this.summary) {
      this.summary.addEventListener('click', this.handleSummaryClick);
      this.measure();
    }
  }

  disconnect() {
    if (this.disconnectObservers) {
      this.disconnectObservers();
    }
    if (this.summary) {
      this.summary.removeEventListener('click', this.handleSummaryClick);
    }
    if (this.animation) {
      this.animation.cancel();
    }
  }

  private getAnimationOptions(): KeyframeAnimationOptions {
    return {
      easing: window.getComputedStyle(this.details).animationTimingFunction,
      // The duration is set to the height to maintain a similar animation speed
      // regardless of the height of the content.
      duration: Math.min(500, Math.max(100, this.openedHeight)),
    };
  }

  private measure() {
    if (!this.summary) return;
    const initialOpen = this.details.open;
    this.closedHeight = this.summary.getBoundingClientRect().height;
    if (!initialOpen) {
      this.details.open = true;
    }

    let detailsHeight = this.details.getBoundingClientRect().height;
    // Add border height to avoid janky animation with exclusive/non-multiple accordions.
    if (detailsHeight > 0) {
      const { borderTopWidth, borderBottomWidth } = window.getComputedStyle(
        this.details
      );
      detailsHeight += parseInt(borderTopWidth) + parseInt(borderBottomWidth);
    }
    this.openedHeight = detailsHeight;

    if (!initialOpen) {
      this.details.open = false;
    }

    let previousWidth = this.details.getBoundingClientRect().width;

    // After measuring, observe the details element for changes in width
    // or content and reset the height on any change so that it's re-measured
    // on the next toggle.
    let resizeObserver: ResizeObserver, mutationObserver: MutationObserver;
    const reset = () => {
      this.openedHeight = 0;
      if (this.disconnectObservers) {
        this.disconnectObservers();
      }
    };
    if (this.disconnectObservers) {
      this.disconnectObservers();
    }
    this.disconnectObservers = () => {
      resizeObserver.disconnect();
      mutationObserver.disconnect();
    };
    resizeObserver = new ResizeObserver((entries) => {
      const { width } = entries[0].contentRect;
      if (width !== previousWidth) {
        reset();
      }
    });
    mutationObserver = new MutationObserver(reset);
    mutationObserver.observe(this.details, { childList: true, subtree: true });
    resizeObserver.observe(this.details);
  }

  private handleAnimationFinish = () => {
    this.animation = null;
    this.state = 'idle';
    this.details.style.overflow = this.initialOverflow || '';
    if (this.onAnimationFinish) {
      this.onAnimationFinish(this.details.open);
    }
  };

  private handleSummaryClick = (event: MouseEvent) => {
    event.preventDefault();
    if (this.details.open) {
      this.close();
    } else {
      this.open();
    }
  };

  close() {
    if (this.animation && this.state === 'opening') {
      this.animation.reverse();
    }

    if (!this.animation || this.state === 'idle') {
      this.state = 'closing';
      this.measure();
      this.animation = this.details.animate(
        { height: [`${this.openedHeight}px`, `${this.closedHeight}px`] },
        this.getAnimationOptions()
      );
      this.animation.addEventListener('finish', this.handleAnimationFinish);
      this.animation.addEventListener('cancel', () => {
        this.state = 'idle';
      });
    }

    this.details.open = false;
  }

  open() {
    if (!this.animation) {
      // If the details was measured while within a closed details element
      // the height will be 0 so we need to measure again.
      if (this.openedHeight === 0) {
        this.measure();
      } else if (this.summary) {
        this.closedHeight = this.summary.getBoundingClientRect().height;
      }
    }

    this.details.style.overflow = 'hidden';

    if (this.animation && this.state === 'closing') {
      this.animation.reverse();
    }

    if (!this.animation || this.state === 'idle') {
      this.state = 'opening';
      this.animation = this.details.animate(
        { height: [`${this.closedHeight}px`, `${this.openedHeight}px`] },
        this.getAnimationOptions()
      );
      this.animation.addEventListener('finish', this.handleAnimationFinish);
      this.animation.addEventListener('cancel', () => {
        this.state = 'idle';
      });
    }

    this.details.open = true;
  }
}

function enableAnimation() {
  return (
    typeof document.documentElement.animate === 'function' &&
    window.matchMedia('(prefers-reduced-motion: no-preference)').matches
  );
}
