import {
  type AriaAttributes,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  getNeedsContentAreaWorkaround,
  scrollToElement,
  useScrollEnd,
  useSnapTargets,
} from './snap-utils';

export interface UseSnapNavigationResults {
  /**
   * The props to spread on the arrow button that navigates to the previous frame.
   *
   * Since the scroll container can also be scrolled using the keyboard,
   * the button is hidden from screen readers.
   */
  previousButtonProps: {
    tabIndex: number;
    'aria-hidden': AriaAttributes['aria-hidden'];
    'aria-label': '';
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onPointerDown: (event: React.PointerEvent<HTMLButtonElement>) => void;
    disabled: boolean;
    hidden?: boolean;
  };

  /**
   * The props to spread on the arrow button that navigates to the next frame.
   *
   * Since the scroll container can also be scrolled using the keyboard,
   * the button is hidden from screen readers.
   */
  nextButtonProps: {
    tabIndex: number;
    'aria-hidden': AriaAttributes['aria-hidden'];
    'aria-label': '';
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onPointerDown: (event: React.PointerEvent<HTMLButtonElement>) => void;
    disabled: boolean;
    hidden?: boolean;
  };
}

export interface UseSnapNavigationOptions {
  /**
   * The ref to the scrollable container element.
   */
  ref:
    | React.RefObject<HTMLElement | null>
    | React.MutableRefObject<HTMLElement | undefined>;
}

export function useSnapNavigation({
  ref,
}: UseSnapNavigationOptions): UseSnapNavigationResults {
  // We keep a separate index while navigating to allow the index to update
  // faster than the scrolling happens. Otherwise pressing next multiple times
  // quickly doesn't do anything – we want it to immediately increment one frame
  // per click.
  const [navigatedIndex, setNavigatedIndex] = useState<number>(-1);
  useScrollEnd(
    ref,
    useCallback(() => {
      setNavigatedIndex(-1);
    }, [])
  );

  const frames = useSnapTargets(ref);
  const [needsContentAreaWorkaround, setNeedsContentAreaWorkaround] =
    useState(false);
  const [rootMargin, setRootMargin] = useState<string | null>(null);

  // A reference to the previous visible elements to avoid ending up with 0
  // visible elements when the user is in-between two elements.
  const previousVisibleFrames = useRef<Set<HTMLElement> | undefined>();
  const [actualVisibleFrames, setVisibleFrames] = useState<
    Set<HTMLElement> | undefined
  >();
  let visibleFrames: Set<HTMLElement> | undefined;
  if (actualVisibleFrames) {
    visibleFrames =
      actualVisibleFrames.size > 0
        ? actualVisibleFrames
        : previousVisibleFrames.current;
  }

  let firstFrame: HTMLElement | undefined;
  let lastFrame: HTMLElement | undefined;
  let nextFrame: HTMLElement | undefined;
  let prevFrame: HTMLElement | undefined;

  let firstVisibleFrameIndex = -1;
  let nextFrameIndex = -1;
  let prevFrameIndex = -1;
  let lastFrameIndex = -1;

  // Prev is disabled by default since we start at the first frame, avoiding
  // flashing it as non-disabled when SSR:d.
  let prevDisabled = true;
  let nextDisabled = false;

  if (frames) {
    if (visibleFrames) {
      firstVisibleFrameIndex = frames.findIndex((frame) =>
        visibleFrames.has(frame)
      );
      const activeIndex =
        navigatedIndex > -1
          ? Math.max(0, Math.min(frames.length - 1, navigatedIndex))
          : firstVisibleFrameIndex;
      prevFrameIndex = Math.max(0, activeIndex - 1);
      nextFrameIndex = Math.min(activeIndex + 1, frames.length - 1);
      lastFrameIndex = frames.length - 1;
      prevFrame = frames[prevFrameIndex];
      nextFrame = frames[nextFrameIndex];
    }

    firstFrame = frames[0];
    lastFrame = frames[frames.length - 1];

    if (actualVisibleFrames) {
      // Disabled states are optimistically updated based on navigatedIndex
      // and then based on actualVisibleFrames to always update as early as
      // possible and not show an incorrect state.
      prevDisabled =
        navigatedIndex === 0 || actualVisibleFrames.has(firstFrame);
      nextDisabled =
        navigatedIndex >= frames.length - 1 ||
        actualVisibleFrames.has(lastFrame);
    }
  }

  useEffect(() => {
    getNeedsContentAreaWorkaround().then(setNeedsContentAreaWorkaround);
  }, []);

  useEffect(() => {
    const scrollport = ref.current;
    if (!scrollport) {
      setRootMargin(null);
      return;
    }

    function updateRootMargin() {
      if (!scrollport) {
        setRootMargin(null);
        return;
      }

      let rootMarginInline = 0;
      const scrollPortStyle = window.getComputedStyle(scrollport);

      // If there's scroll-padding, the intersection should be calculated from
      // where the snap will happen (with the scroll-padding), not from the edge
      // of the scrollport.
      let scrollPaddingInline = parseInt(scrollPortStyle.scrollPaddingInline);
      if (Number.isNaN(scrollPaddingInline)) {
        scrollPaddingInline = 0;
      }

      // Workaround for Safari bug where the content area isn't calculated correctly.
      // See https://github.com/w3c/IntersectionObserver/issues/504
      if (needsContentAreaWorkaround) {
        const paddingInline = parseInt(scrollPortStyle.paddingInline);
        if (!Number.isNaN(paddingInline) && paddingInline > 0) {
          rootMarginInline = paddingInline - scrollPaddingInline;
        }
      } else if (scrollPaddingInline > 0) {
        rootMarginInline = -scrollPaddingInline;
      }

      setRootMargin(
        rootMarginInline !== 0 ? `0px ${rootMarginInline}px` : null
      );
    }
    updateRootMargin();

    const resizeObserver = new ResizeObserver(() => {
      updateRootMargin();
    });

    resizeObserver.observe(scrollport);
    return () => {
      resizeObserver.disconnect();
    };
  }, [ref, needsContentAreaWorkaround]);

  useEffect(() => {
    setVisibleFrames(new Set());
    const scrollport = ref.current;
    if (!frames || frames.length === 0 || !scrollport) {
      return;
    }

    // These thresholds control at what scroll position the next/previous
    // buttons update to become enabled/disabled.
    const threshold = [0.01, 0.03, 0.97, 0.99];
    const intersectionObserver = new IntersectionObserver(
      (list) => {
        const removedNodes: HTMLElement[] = [];
        const addedNodes: HTMLElement[] = [];

        for (const item of list) {
          const node = item.target as HTMLElement;
          if (item.intersectionRatio >= 0.98) {
            addedNodes.push(node);
          } else {
            removedNodes.push(node);
          }
        }
        setVisibleFrames((prev) => {
          let frames = prev || new Set();
          for (const node of removedNodes) {
            frames.delete(node);
          }
          for (const node of addedNodes) {
            frames.add(node);
          }
          if (frames.size > 0) {
            previousVisibleFrames.current = frames;
          }
          return new Set(frames);
        });
      },
      { root: scrollport, threshold, rootMargin: rootMargin || undefined }
    );
    for (const frame of frames) {
      intersectionObserver.observe(frame);
    }
    return () => {
      intersectionObserver.disconnect();
    };
  }, [frames, ref, rootMargin]);

  const previousButtonProps = useMemo(
    () =>
      ({
        // We hide the buttons from screen readers since the scroll container
        // can already be navigated using the keyboard.
        tabIndex: -1,
        'aria-hidden': true,
        'aria-label': '',
        onPointerDown(event: React.PointerEvent<HTMLButtonElement>) {
          // Prevents "Blocked aria-hidden on an element because its descendant retained focus." warning
          // in Chrome. The button remains hidden from screen readers using keyboard navigation since it
          // can't be focused and thus not activated/clicked.
          if (event.currentTarget.ariaHidden === 'true') {
            event.currentTarget.removeAttribute('aria-hidden');
          }
        },
        onClick() {
          const scrollport = ref.current;
          if (!scrollport || !firstFrame) return;

          scrollToElement(scrollport, prevFrame || firstFrame);

          setNavigatedIndex((prev) => {
            const isNavigating = prev !== -1;
            return Math.max(
              0,
              isNavigating ? prev - 1 : firstVisibleFrameIndex - 1
            );
          });

          function scrollHandler() {
            clearTimeout(timeout);
          }
          const timeout = setTimeout(() => {
            scrollport.removeEventListener('scroll', scrollHandler);
            setNavigatedIndex(-1);
          }, 100);
          // In case scrolling doesn't happen, we reset the navigated index after a delay.
          scrollport.addEventListener('scroll', scrollHandler, { once: true });
        },
        disabled: prevDisabled,
      } as const),
    [ref, prevDisabled, prevFrame, firstFrame, firstVisibleFrameIndex]
  );

  const nextButtonProps = useMemo(
    () =>
      ({
        // We hide the buttons from screen readers since the scroll container
        // can already be navigated using the keyboard.
        tabIndex: -1,
        'aria-hidden': true,
        'aria-label': '',
        onPointerDown(event: React.PointerEvent<HTMLButtonElement>) {
          // Prevents "Blocked aria-hidden on an element because its descendant retained focus." warning
          // in Chrome. The button remains hidden from screen readers using keyboard navigation since it
          // can't be focused and thus not activated/clicked.
          if (event.currentTarget.ariaHidden === 'true') {
            event.currentTarget.removeAttribute('aria-hidden');
          }
        },
        onClick: () => {
          const scrollport = ref.current;
          if (!scrollport || !lastFrame) return;
          scrollToElement(scrollport, nextFrame || lastFrame);

          setNavigatedIndex((prev) => {
            const isNavigating = prev !== -1;
            const index = isNavigating ? prev + 1 : firstVisibleFrameIndex + 1;
            return Math.min(lastFrameIndex, index);
          });

          function scrollHandler() {
            clearTimeout(timeout);
          }
          const timeout = setTimeout(() => {
            scrollport.removeEventListener('scroll', scrollHandler);
            setNavigatedIndex(-1);
          }, 100);
          // In case scrolling doesn't happen, we reset the navigated index after a delay.
          scrollport.addEventListener('scroll', scrollHandler, { once: true });
        },
        disabled: nextDisabled,
      } as const),
    [
      ref,
      nextDisabled,
      nextFrame,
      lastFrameIndex,
      lastFrame,
      firstVisibleFrameIndex,
    ]
  );

  return {
    previousButtonProps,
    nextButtonProps,
  };
}
