import { Children, cloneElement, forwardRef } from 'react';
import { Icon } from './icon';
import { type IconName } from './icons.generated';

type BaseProps = {
  /**
   * The icon to render in the IconButton using the 1.0 icon system.
   */
  icon: IconName;

  /**
   * Use the filled variant of the icon, typically for active or selected states.
   */
  iconFilled?: boolean;

  /**
   * The visual style of the IconButton
   *
   * @default 'outlined'
   */
  variant?: 'outlined' | 'filled' | 'clear';

  /**
   * The color of the IconButton
   *
   * @default 'neutral'
   */
  color?: 'neutral' | 'destructive';

  /**
   * Allows the background to bleed out into the surrounding layout,
   * leaving the button to only take up the space for the icon itself.
   *
   * Only available for the clear variant.
   *
   * @default false
   */
  bleed?: boolean;

  /**
   * Disables the button. Avoid using disabled buttons whenever possible - instead show error messages explaining the next steps the user should take.
   */
  disabled?: boolean;

  /**
   * Custom class name, merged with existing classes.
   */
  className?: string;

  hidden?: boolean;
  id?: string;
  title?: string;
  dir?: string;
  lang?: string;
  slot?: string;
  style?: React.CSSProperties;
  tabIndex?: number;
};

type AriaProps =
  | { 'aria-label': string; 'aria-labelledby'?: never }
  | { 'aria-label'?: never; 'aria-labelledby': string };

type AnchorProps = { href: string; onClick?: never; children?: never };

type ButtonProps = {
  href?: never;
  children?: never;
  /**
   * Id of a form element that this button should be associated with. Defaults to the containing
   * form element.
   */
  form?: string;
  /**
   * The URL that processes the information submitted by the button, overriding the `action` attribute of the button's form.
   */
  formAction?: string;
  /**
   * Specifies the HTTP method used to submit the form, overriding the `method` attribute of the button's form.
   */
  formMethod?: 'post' | 'get';

  /**
   * The name of the button, submitted as a pair with the button's value as part of the form data.
   */
  name?: string;
  /**
   * The value associated with the button's `name` in the form data when the form is submitted using this button.
   */
  value?: string;

  /**
   * @default 'button'
   */
  type?: 'submit' | 'button';

  /**
   * Called when the button is clicked.
   *
   * If used within a `<form>`, prefer to set the button to `type=submit` and use the `onSubmit` event on the form
   * instead.
   */
  onClick?: React.MouseEventHandler<HTMLButtonElement>;

  onBlur?: React.FocusEventHandler<HTMLButtonElement>;
  onFocus?: React.FocusEventHandler<HTMLButtonElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLButtonElement>;
  onKeyUp?: React.KeyboardEventHandler<HTMLButtonElement>;
  onPointerDown?: React.PointerEventHandler<HTMLButtonElement>;
  onPointerEnter?: React.PointerEventHandler<HTMLButtonElement>;
  onPointerLeave?: React.PointerEventHandler<HTMLButtonElement>;
  onPointerMove?: React.PointerEventHandler<HTMLButtonElement>;
  onPointerUp?: React.PointerEventHandler<HTMLButtonElement>;
  onAnimationEnd?: React.AnimationEventHandler<HTMLButtonElement>;
  onAnimationStart?: React.AnimationEventHandler<HTMLButtonElement>;
  onTransitionEnd?: React.TransitionEventHandler<HTMLButtonElement>;
};

type AsChildProps = {
  asChild: boolean;
  children: React.ReactElement;
  href?: never;
  onClick?: never;
};

export type IconButtonProps = BaseProps &
  AriaProps &
  (AnchorProps | ButtonProps | AsChildProps);

export const IconButton = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  IconButtonProps
>(function IconButton(
  {
    iconFilled,
    icon,
    className = '',
    color = 'neutral',
    variant = 'outlined',
    bleed,
    disabled,
    ...props
  }: IconButtonProps,
  ref
) {
  const sharedProps = {
    className: `icon-button-${variant} ${className}`.trim(),
    'data-color': color === 'neutral' ? undefined : color,
    'data-bleed': bleed && variant === 'clear' ? true : undefined,
    'aria-disabled': disabled || (props as any)['aria-disabled'] || undefined,
  };

  const iconComponent = (
    <Icon
      icon={icon}
      size={16}
      color={
        variant === 'filled' || color !== 'neutral' ? 'currentColor' : undefined
      }
      filled={iconFilled}
    />
  );

  if (props.children && props.asChild) {
    const { children, asChild, ...buttonProps } = props;
    return cloneElement(
      Children.only(children),
      {
        ...buttonProps,
        ...sharedProps,
      },
      iconComponent
    );
  } else if (props.href) {
    return (
      <a
        ref={ref as React.RefObject<HTMLAnchorElement>}
        {...props}
        {...sharedProps}
      >
        {iconComponent}
      </a>
    );
  } else {
    return (
      <button
        ref={ref as React.RefObject<HTMLButtonElement>}
        type="button"
        {...props}
        {...sharedProps}
        onClick={(event) => {
          if (disabled) {
            event.preventDefault();
          } else if (props.onClick) {
            props.onClick(event);
          }
        }}
      >
        {iconComponent}
      </button>
    );
  }
});
