import { ICON_CDN_VERSION } from './icons.generated';

const DEFAULT_BASE_URL = 'https://www.volvocars.com/static/shared/';

let baseUrl = DEFAULT_BASE_URL;

/**
 * Set the custom base URL before rendering, at app startup.
 */
export function setBaseUrl(url: string) {
  if (!url.endsWith('/')) {
    url += '/';
  }
  baseUrl = url;
}

export function getIconSrc(
  name: string,
  size: number,
  filled: boolean
): string {
  const fileSize = size <= 20 ? 16 : 24;
  return `${baseUrl}icons/${ICON_CDN_VERSION}/${name}-${fileSize}.svg${
    filled ? '#filled' : ''
  }`;
}

export function getBrandedIconSrc(name: string): string {
  return `${baseUrl}icons/${ICON_CDN_VERSION}/branded/${name}-24.svg`;
}

export function getWordmarkSrc() {
  return `${baseUrl}images/volvo-spread-wordmark.svg`;
}
