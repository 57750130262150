import { useEffect, useMemo, useRef, useState } from 'react';
import { scrollToElement, useScrollEnd, useSnapTargets } from './snap-utils';

export interface UseSnapIndicatorsResults {
  /**
   * The index of the visible frame.
   */
  activeIndex: number;

  /**
   * An array of props for the indicator elements.
   */
  indicators: Array<{
    onClick: (event: React.MouseEvent) => void;
    'aria-current'?: 'true';
  }>;
}

export interface UseSnapIndicatorsOptions {
  /**
   * The ref to the container element.
   */
  ref:
    | React.RefObject<HTMLElement | null>
    | React.MutableRefObject<HTMLElement | undefined>;
}

export function useSnapIndicators({
  ref,
}: UseSnapIndicatorsOptions): UseSnapIndicatorsResults {
  const snapTargets = useSnapTargets(ref);
  const intersectionRatioBySnapTarget = useRef<Map<HTMLElement, number>>(
    new Map()
  );
  const [activeIndex, setActiveIndex] = useState(0);

  // This allows clicking an indicator to immediately navigate to the target
  // by setting the `activeIndex` instead of just scrolling to it and going
  // through each dot one by one until the target is reached.
  const isNavigating = useRef(false);
  useScrollEnd(ref, () => {
    isNavigating.current = false;
  });

  useEffect(() => {
    const root = ref.current;
    if (!snapTargets || snapTargets.length === 0 || !root) return;
    const rootMargin = window.getComputedStyle(root).paddingInline;
    const intersectionObserver = new IntersectionObserver(
      (list) => {
        for (const item of list) {
          intersectionRatioBySnapTarget.current.set(
            item.target as HTMLElement,
            item.intersectionRatio
          );
        }
        if (!isNavigating.current) {
          let index = 0;
          let maxRatio = 0;
          // Find the item with the most intersection
          for (const [
            snapTarget,
            ratio,
          ] of intersectionRatioBySnapTarget.current) {
            if (ratio > maxRatio) {
              maxRatio = ratio;
              index = snapTargets.indexOf(snapTarget);
            }
          }
          if (index >= 0) {
            setActiveIndex(() => index);
          }
        }
      },
      {
        root,
        threshold: [0.1, 0.2, 0.3, 0.4, 0.49, 0.51, 0.6, 0.7, 0.8, 0.9],
        rootMargin,
      }
    );
    for (const frame of snapTargets) {
      intersectionObserver.observe(frame);
    }
    return () => {
      intersectionObserver.disconnect();
    };
  }, [snapTargets, ref]);

  const indicators = useMemo(() => {
    if (!snapTargets || snapTargets.length <= 1) {
      return [];
    }
    return [
      ...Array.from(
        { length: snapTargets.length },
        (_, i) =>
          ({
            onClick(event: React.MouseEvent) {
              event.preventDefault();
              const scrollport = ref.current;
              if (!scrollport || !snapTargets) return;
              const element = snapTargets[i];
              isNavigating.current = true;
              setActiveIndex(i);
              scrollToElement(scrollport, element);
            },
            'aria-current': i === activeIndex ? 'true' : undefined,
          } as const)
      ),
    ];
  }, [activeIndex, snapTargets, ref]);

  return {
    indicators,
    activeIndex: activeIndex,
  };
}
