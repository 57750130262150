import type { PaginationItem, PaginationProps } from './types';

// TODO: Try to replace this with a more generic implementation
const withLastPage: PaginationItem[][] = [
  [
    { value: 1, type: 'PAGE', isActive: true },
    { value: 2, type: 'PAGE' },
    { value: 3, type: 'PAGE' },
    { value: 4, type: 'PAGE', isAdditional: true },
    { value: 5, type: 'PAGE', isAdditional: true },
    { type: 'ELLIPSIS_SMALL' },
    { value: 6, type: 'PAGE' },
  ],
  [
    { value: 1, type: 'PAGE' },
    { value: 2, type: 'PAGE', isActive: true },
    { value: 3, type: 'PAGE' },
    { value: 4, type: 'PAGE', isAdditional: true },
    { value: 5, type: 'PAGE', isAdditional: true },
    { type: 'ELLIPSIS_SMALL' },
    { value: 6, type: 'PAGE' },
  ],
  [
    { value: 1, type: 'PAGE' },
    { value: 2, type: 'PAGE' },
    { value: 3, type: 'PAGE', isActive: true },
    { value: 4, type: 'PAGE', isAdditional: true },
    { value: 5, type: 'PAGE', isAdditional: true },
    { type: 'ELLIPSIS_SMALL' },
    { value: 6, type: 'PAGE' },
  ],
  [
    { value: 1, type: 'PAGE' },
    { type: 'ELLIPSIS_SMALL' },
    { value: 2, type: 'PAGE', isAdditional: true },
    { value: 3, type: 'PAGE', isAdditional: true },
    { value: 4, type: 'PAGE', isActive: true },
    { value: 5, type: 'PAGE' },
    { value: 6, type: 'PAGE' },
  ],
  [
    { value: 1, type: 'PAGE' },
    { type: 'ELLIPSIS_SMALL' },
    { value: 2, type: 'PAGE', isAdditional: true },
    { value: 3, type: 'PAGE', isAdditional: true },
    { value: 4, type: 'PAGE' },
    { value: 5, type: 'PAGE', isActive: true },
    { value: 6, type: 'PAGE' },
  ],
  [
    { value: 1, type: 'PAGE' },
    { type: 'ELLIPSIS_SMALL' },
    { value: 2, type: 'PAGE', isAdditional: true },
    { value: 3, type: 'PAGE', isAdditional: true },
    { value: 4, type: 'PAGE' },
    { value: 5, type: 'PAGE' },
    { value: 6, type: 'PAGE', isActive: true },
  ],
];

const withoutLastPage: PaginationItem[][] = [
  [
    { value: 1, type: 'PAGE', isActive: true },
    { value: 2, type: 'PAGE' },
    { value: 3, type: 'PAGE' },
    { value: 4, type: 'PAGE', isAdditional: true },
    { value: 5, type: 'PAGE', isAdditional: true },
    { type: 'ELLIPSIS_SMALL' },
    { value: 6, type: 'PAGE', isAdditional: true },
  ],
  [
    { value: 1, type: 'PAGE' },
    { value: 2, type: 'PAGE', isActive: true },
    { value: 3, type: 'PAGE' },
    { value: 4, type: 'PAGE', isAdditional: true },
    { value: 5, type: 'PAGE', isAdditional: true },
    { type: 'ELLIPSIS_SMALL' },
    { value: 6, type: 'PAGE', isAdditional: true },
  ],
  [
    { value: 1, type: 'PAGE' },
    { value: 2, type: 'PAGE' },
    { value: 3, type: 'PAGE', isActive: true },
    { value: 4, type: 'PAGE', isAdditional: true },
    { value: 5, type: 'PAGE', isAdditional: true },
    { type: 'ELLIPSIS_SMALL' },
    { value: 6, type: 'PAGE', isAdditional: true },
  ],
  [
    { value: 1, type: 'PAGE' },
    { type: 'ELLIPSIS_SMALL' },
    { value: 2, type: 'PAGE', isAdditional: true },
    { value: 3, type: 'PAGE', isAdditional: true },
    { value: 4, type: 'PAGE', isActive: true },
    { value: 5, type: 'PAGE', isAdditional: true },
    { type: 'ELLIPSIS_SMALL' },
    { value: 6, type: 'PAGE', isAdditional: true },
  ],
  [
    { value: 1, type: 'PAGE' },
    { type: 'ELLIPSIS_SMALL' },
    { value: 2, type: 'PAGE', isAdditional: true },
    { value: 3, type: 'PAGE', isAdditional: true },
    { value: 4, type: 'PAGE', isAdditional: true },
    { value: 5, type: 'PAGE', isActive: true },
    { value: 6, type: 'PAGE' },
  ],
  [
    { value: 1, type: 'PAGE' },
    { type: 'ELLIPSIS_SMALL' },
    { value: 2, type: 'PAGE', isAdditional: true },
    { value: 3, type: 'PAGE', isAdditional: true },
    { value: 4, type: 'PAGE', isAdditional: true },
    { value: 5, type: 'PAGE' },
    { value: 6, type: 'PAGE', isActive: true },
  ],
];

export const getPagination6Pages = ({
  page = 1,
  showLastPage,
}: PaginationProps): PaginationItem[] => {
  return showLastPage ? withLastPage[page - 1] : withoutLastPage[page - 1];
};
