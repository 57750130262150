import { forwardRef } from 'react';
import { getWordmarkSrc } from './config';

const COLOR = ['inverted', 'primary'] as const;

export type LogoColor = (typeof COLOR)[number] | 'currentColor';

export type WordmarkProps = {
  className?: string;
  role?: React.ImgHTMLAttributes<HTMLImageElement>['role'];
  /**
   * Alternate text.
   *
   * @default Volvo
   */
  alt?: string;

  /**
   * Set the height of the wordmark (use multiples of 8). The width will be set to 13 times the height.
   *
   * @default 8
   */
  height?: number;
};

/**
 * Wordmark renders an `img` with a src on the volvocars.com CDN, automatically picking up the primary foreground color for the current color mode
 * and zooming with the browser font size.
 */
export const Wordmark = forwardRef<HTMLImageElement, WordmarkProps>(
  function Wordmark({ alt = 'Volvo', height = 8, ...props }, ref) {
    return (
      <img
        {...props}
        ref={ref}
        className={`wordmark text-primary ${props.className || ''}`.trim()}
        width={height * 13}
        height={height}
        src={getWordmarkSrc()}
        alt={alt}
        style={
          {
            '--mask-url': `url('${getWordmarkSrc()}')`,
          } as React.CSSProperties
        }
      />
    );
  }
);
