import { type ClassValue, cssJoin } from './join';

const SPLIT_CLASSES_REGEX = /\s+/;

/**
 * Class group matches with classes that should override each other.
 *
 * The keys are just arbitrary names and not neccesarily part of the actual class name.
 */
const classGroupMatchers = new Map([
  // Key + alphanumeric value
  ...['scale', 'stack', 'top', 'end', 'bottom', 'start'].map((name) => {
    return [name, new RegExp(`^${name}-[\\w\\d\\/]+$`)] as const;
  }),

  // Key + optional numeric value
  ...[
    'h',
    'w',
    'grid-cols',
    'border',
    'border-ring',
    'border-x',
    'border-y',
    'border-r',
    'border-l',
    'border-t',
    'border-b',
  ].map((name) => {
    return [name, new RegExp(`^${name}(-\\d+)?$`)] as const;
  }),

  // Key + optional single word value
  ...['rounded', 'rounded-t', 'rounded-e', 'rounded-b', 'rounded-s'].map(
    (name) => {
      return [name, new RegExp(`^${name}(-\\w+)?$`)] as const;
    }
  ),

  // Key + single word value
  ...[
    'button',
    'contain',
    'container',
    'items',
    'justify',
    'link',
    'overflow-x',
    'overflow-y',
    'overflow',
    'self',
    'transition',
  ].map((name) => {
    return [name, new RegExp(`^${name}-\\w+$`)] as const;
  }),

  // Key + multiple word value
  ...['bg', 'whitespace'].map((name) => {
    return [name, new RegExp(`^${name}-[\\w-]+$`)] as const;
  }),

  // Key + alphanumeric value with optional - prefix
  ...[
    'p',
    'pt',
    'pr',
    'pb',
    'pl',
    'px',
    'py',
    'm',
    'mt',
    'mr',
    'mb',
    'ml',
    'mx',
    'my',
    'gap',
    'gap-y',
    'gap-x',
    'translate-x',
    'translate-y',
  ].map((name) => {
    return [name, new RegExp(`^-?${name}-[\\w\\d\\/]+$`)] as const;
  }),

  ['border-color', /^border-(?!(ring|[tlrb]))[\w-]{2,}$/],
  ['flex', /^flex(-col|-row)(-reverse)?$/],
  ['snap-align', /^snap-(start|center|end)$/],
  ['snap-dir', /^snap-[xy]$/],
  ['flex-wrap', /^flex-(no)?wrap$/],
  ['display-outer', /^(inline|block|hidden)$/],
  ['display-inner', /^(flex|grid|flow-root)$/],
  ['grow', /^flex-grow(-\\d)?$/],
  ['shrink', /^flex-shrink(-\\d)?$/],
  ['weight', /^font-(light|medium)$/],
  ['size', /^((heading|title|body|statement)-[\w\d]+|micro|font-\d+)$/],
  ['text-color', /^text-(?!start|center|end|balance)[\w-]+$/],
  ['aspect', /^aspect-\d+\/\d+/],
  ['position', /^(absolute|fixed|relative|static|sticky)$/],
  [
    'text-align',
    // Needs explicit regexp to avoid matching text-color or text-wrap
    /^text-(start|center|end)$/,
  ],
]);

// The keys are group names that override the group names in the values.
// For example `p-0` overrides both `py-8` and `pr-8`.
const overridingGroups = new Map([
  ['p', ['px', 'py', 'pt', 'pr', 'pb', 'pl']],
  ['px', ['pr', 'pl']],
  ['py', ['pt', 'pb']],
  ['m', ['mx', 'my', 'mt', 'mr', 'mb', 'ml']],
  ['mx', ['mr', 'ml']],
  ['my', ['mt', 'mb']],
  ['gap', ['gap-x', 'gap-y']],
  [
    'border',
    ['border-x', 'border-y', 'border-t', 'border-r', 'border-b', 'border-l'],
  ],
  ['border-x', ['border-l', 'border-r']],
  ['border-y', ['border-t', 'border-b']],
  ['rounded', ['rounded-t', 'rounded-e', 'rounded-b', 'rounded-s']],
  ['overflow', ['overflow-x', 'overflow-y']],
]);

/**
 * Merges conflicting classes that apply to the same CSS property.
 * E.g. turns `px-8 p-0` into `p-0`.
 *
 * Classes are first passed through `cssJoin`.
 *
 * @param classes
 * @returns
 */
export function cssMerge(...classes: ClassValue[]): string {
  const result = new Set<string>();
  const includedGroups = new Map<string, RegExp>();
  const unique = new Set(cssJoin(classes).split(SPLIT_CLASSES_REGEX).reverse());
  outer: for (const className of unique) {
    const [prefix, actualClass] = parseClassName(className);

    // Skip classes that are already included
    for (const [key, re] of includedGroups) {
      const [includedPrefix] = parseClassName(key);
      if (includedPrefix === prefix && re.test(actualClass)) {
        continue outer;
      }
    }

    for (const [group, re] of classGroupMatchers) {
      if (re.test(actualClass)) {
        includedGroups.set(prefix ? `${prefix}:${group}` : group, re);
        for (const override of overridingGroups.get(group) ?? []) {
          includedGroups.set(
            prefix ? `${prefix}:${override}` : override,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            classGroupMatchers.get(override)!
          );
        }
      }
    }
    result.add(className);
  }
  return [...result].reverse().join(' ');
}

function parseClassName(
  className: string
): [prefix: string, actualClassName: string] {
  const parts = className.split(':');
  return parts.length === 1 ? ['', parts[0]] : (parts as [string, string]);
}
