import { useEffect, useState } from 'react';

function getAllSnapTargets(scrollport: HTMLElement) {
  const treeWalker = getSnapTargetTreeWalker(scrollport);
  const snapTargets = [];
  while (treeWalker.nextNode()) {
    snapTargets.push(treeWalker.currentNode);
  }
  return snapTargets as HTMLElement[];
}

function getSnapTargetTreeWalker(scrollport: HTMLElement) {
  return document.createTreeWalker(scrollport, NodeFilter.SHOW_ELEMENT, {
    acceptNode(node: HTMLElement) {
      return isSnapTarget(node)
        ? NodeFilter.FILTER_ACCEPT
        : NodeFilter.FILTER_SKIP;
    },
  });
}

function isSnapTarget(node: Node) {
  return (
    node.nodeType === 1 &&
    window.getComputedStyle(node as HTMLElement).scrollSnapAlign !== 'none'
  );
}

export function scrollToElement(scrollport: HTMLElement, element: HTMLElement) {
  const isRtl = scrollport.matches(':dir(rtl)');
  let offset = parseInt(
    window.getComputedStyle(scrollport).scrollPaddingInline
  );
  if (Number.isNaN(offset)) {
    offset = 0;
  }
  let left;
  if (isRtl) {
    left =
      element.offsetWidth +
      element.offsetLeft -
      (scrollport.clientWidth - offset);
  } else {
    left = element.offsetLeft - offset;
  }
  requestAnimationFrame(() => {
    scrollport.scrollTo({ left });
  });
}

export function useSnapTargets(
  ref:
    | React.RefObject<HTMLElement | null>
    | React.MutableRefObject<HTMLElement | undefined>
) {
  const [frames, setFrames] = useState<HTMLElement[] | undefined>();

  useEffect(() => {
    const scrollroot = ref.current;
    if (!scrollroot) return;

    function updateFramesFromSnapTargets(scrollroot: HTMLElement) {
      const targets = getAllSnapTargets(scrollroot);
      setFrames(targets);
    }

    updateFramesFromSnapTargets(scrollroot);

    // Keeps the frames up to date for example with lazy loading
    // or if a filter is applied to the children of the scrollport.
    const observer = new MutationObserver((mutationList) => {
      let didChangeSnapTargets = false;
      for (const mutation of mutationList) {
        if (didChangeSnapTargets) {
          break;
        }
        if (mutation) {
          for (const node of mutation.addedNodes) {
            if (didChangeSnapTargets) {
              break;
            }
            didChangeSnapTargets = isSnapTarget(node);
          }
          for (const node of mutation.removedNodes) {
            if (didChangeSnapTargets) {
              break;
            }
            didChangeSnapTargets = isSnapTarget(node);
          }
        }
      }
      if (didChangeSnapTargets) {
        updateFramesFromSnapTargets(scrollroot);
      }
    });

    observer.observe(scrollroot, { childList: true, subtree: true });
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return frames;
}

export function useScrollEnd(
  ref:
    | React.RefObject<HTMLElement | null>
    | React.MutableRefObject<HTMLElement | undefined>,
  callback: () => void
) {
  useEffect(() => {
    const scrollport = ref.current;
    if (!scrollport) return;
    let scrollListener: (event: Event) => void;
    let scrollEvent: 'scroll' | 'scrollend' = 'scroll';
    if ('onscrollend' in window) {
      scrollEvent = 'scrollend';
      scrollListener = () => {
        callback();
      };
    } else {
      let timeout = 0;
      scrollListener = () => {
        clearTimeout(timeout);
        timeout = window.setTimeout(() => {
          timeout = 0;
          callback();
        }, 100);
      };
    }
    scrollport.addEventListener(scrollEvent, scrollListener, { passive: true });
    return () => {
      scrollport.removeEventListener(scrollEvent, scrollListener);
    };
  }, [ref, callback]);
}

let needsContentAreaWorkaround: boolean | undefined;
// Workaround for Safari bug where the content area isn't calculated correctly.
// See https://github.com/w3c/IntersectionObserver/issues/504
export async function getNeedsContentAreaWorkaround() {
  if (typeof needsContentAreaWorkaround === 'boolean') {
    return Promise.resolve(needsContentAreaWorkaround);
  }
  const root = document.createElement('div');
  root.style.width = '20px';
  root.style.height = '1px';
  root.style.paddingInlineStart = '10px';
  root.style.contain = 'content';
  const child = root.cloneNode() as HTMLElement;
  root.append(child);
  document.body.append(root);
  return new Promise<boolean>((resolve) => {
    const io = new IntersectionObserver(
      (list) => {
        const item = list[0];
        if (item.rootBounds && item.rootBounds.width > 0) {
          needsContentAreaWorkaround =
            item.rootBounds.width !== item.boundingClientRect.width;
          resolve(needsContentAreaWorkaround);
          io.disconnect();
          document.body.removeChild(root);
        }
      },
      { root }
    );
    io.observe(child);
  });
}
